import { OnInit, Renderer2, ElementRef, Injectable } from '@angular/core';

@Injectable()
export class Icon implements OnInit {

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit() {
    const svg = this.el.nativeElement.querySelector('svg');
    this.renderer.setStyle(svg, 'display', 'block');
  }

}
