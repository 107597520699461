import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckMarkComponent } from './check-mark/check-mark.component';
import { CrossComponent } from './cross/cross.component';
import { CancelComponent } from './cancel/cancel.component';
import { MagnifierComponent } from './magnifier/magnifier.component';
import { ArrowLeftComponent } from './arrow-left/arrow-left.component';
import { ArrowRightComponent } from './arrow-right/arrow-right.component';

@NgModule({
  declarations: [
    CheckMarkComponent,
    CrossComponent,
    CancelComponent,
    MagnifierComponent,
    ArrowLeftComponent,
    ArrowRightComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CheckMarkComponent,
    CrossComponent,
    CancelComponent,
    MagnifierComponent,
    ArrowLeftComponent,
    ArrowRightComponent
  ]
})
export class NgxIconmonstrModule { }
